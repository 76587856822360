<template>
	<div class="our-ports relative-container">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="AnchorIcon"
				title="Our Ports"
				colour="blue"
			/>
			<div class="section-gap section-port-list">
				<div class="section-title-wrap">
					<h2 class="section-title">{{ count }} Port{{ count > 1 ? 's' : '' }}</h2>
				</div>
                <div v-for="(values, continent) in ports" v-bind:key="continent" class="port-list-wrap gap">
                    <span class="post-list-sub">{{ continent }}</span>
                    <PortListBlock
                        v-for="port in values"
                        v-bind:key="port.id"
                        :id="port.id"
                        :port="port.name"
                        :country="port.country"
                        :countryCode="port.country_code"
                    />
                </div><!-- port list wrap -->
			</div>
			<div class="section-gap-big section-ports">
				<!-- <div class="field field-icon field-icon-left">
					<div class="control">
						<input class="search-field" placeholder="Find more Ports" type="text" v-model="search">
						<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
					</div>
				</div> -->
			</div>
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	// import SearchIcon from '@/assets/search.svg';
	import Card from '@/components/Card.vue';
	import PortCard from '@/components/PortCard.vue';
	import PortListBlock from '@/components/PortListBlock.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import PortService from '@/services/PortService';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';
	export default {
		name: 'OurPorts',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			// SearchIcon,
			PortListBlock,
			SectionTitle,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
                count: 0,
                ports: []
	        };
	    },
	    methods: {
            getPorts () {
                PortService.getPorts().then((response) => {
                    this.count = response.data.count;
                    this.ports = response.data.ports;
                });
            },
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
		},
		mounted () {
            this.getPorts();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.search-field {
        padding: 0 50px !important;
    }
</style>
